import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PlaidLink from 'react-plaid-link'

import {
	Flex,
	Heading,
	Block,
	Paragraph,
} from 'reakit'

import { Container, PhoneBody, Modal } from '../../components/layouts'


import {
	Card,
	Button,
	Image,
	StripeCheckout,
	Loader,
	Link
} from '../../components/ui'

import {
	linkBank, resetBankState
} from '../../factory'
import {PLAID_ENV} from '../../factory/types'

import Layout from '../../components/layout'

import theme from '../../theme/content'
import {getUrlVars} from '../../util'
import { navigate } from '@reach/router'


class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			has_chargable_accounts: false,
			showError: false
		}

		this.setChargeable = this.setChargeable.bind(this)
		this.handleOnSuccess = this.handleOnSuccess.bind(this)
	}

	componentDidMount() {
		this.props.resetBankState()
		this.setChargeable(getUrlVars().chargeable === 'true')
	}

	setChargeable(has_chargable_accounts) {
		this.setState({has_chargable_accounts})
	}

	handleOnSuccess(plaid_token, _) {
		this.props.linkBank(plaid_token, this.props.token, ()=>navigate('/register/complete/'), ()=> this.setState({showError: true}))
	}


	handleOnExit() {
		// handle the case when your user exits Link
	}

	render() {
		const {bank_pending, error} = this.props
		const {showError} = this.state
		return (
			<Layout>
				<Container>
					<PhoneBody title={"Round Up Account"}>
						<Flex column height={'100%'} justifyContent={"center"} alignItems={"center"} marginTop={25}>
							<Card style={{backgroundColor: theme.colors.sunglow, width: '80%', borderRadius: 25, textAlign: 'center'}}>
								<Heading as="h1" fontSize={"24px"} textAlign={'center'}>Link an account for Round Ups</Heading>
								<Paragraph>Link your most active bank account, so your roundups are computed most often! If you want to add a third bank, don't worry! You always can in your profile.</Paragraph>
								<PlaidLink
									clientName="Flourish"
									env={PLAID_ENV}
									product={"transactions"}
									publicKey="d0d722e2447e775a33bc590c6687ac"
									onExit={this.handleOnExit}
									style={{borderRadius: '50px', width: 200, height: '2.5em', fontFamily: 'work-sans'}}
									onSuccess={this.handleOnSuccess}>
									<Paragraph>Search Banks</Paragraph>
								</PlaidLink>
							</Card>
							<div style={{height: 2, width: '25%', minWidth: 250, background: theme.colors.mineShaft, marginTop: 25, marginBottom: 10}}/>
							<Paragraph>Looking to link another funding source?</Paragraph>
							<Modal
								link={{text: "Link a Credit Card"}}
								containterStyle={{maxWidth: 450, width: '90%'}}
							>
								<div style={{width: '80%'}}>
									<Heading as={"h1"} fontSize={'24px'}>Please enter your credit card:</Heading>
									<StripeCheckout redirect={'/register/complete/'}/>
								</div>
							</Modal>
						</Flex>
						{bank_pending && <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, background: "rgba(10, 10, 10, .4)"}}>
							<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', height: '100%'}}>
								<div style={{background: 'white', flexDirection: 'column', width: '55%', maxWidth: 250, margin: 'auto', alignItems: 'center', textAlign: 'center', padding: 10}}>
									<Loader/>
									<Heading as="h1" fontSize={24}>Linking Account...</Heading>
								</div>
							</div>
						</div>
						}
						{showError && <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, background: "rgba(10, 10, 10, .4)"}}>
							<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', height: '100%'}}>
								<div style={{background: 'white', flexDirection: 'column', width: '55%', maxWidth: 250, margin: 'auto', alignItems: 'center', textAlign: 'center', padding: 10}}>
									<Heading as="h1" fontSize={24}>Uh oh!</Heading>
									<Paragraph>{error}</Paragraph>
									<Button onClick={()=>this.setState({showError: false})}>Close</Button>
								</div>
							</div>
						</div>
						}
						<Link style={{textAlign: 'center', marginTop: 150}} href={'/register/complete/'}>Skip</Link>
					</PhoneBody>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	user: PropTypes.object,
	token: PropTypes.string,
	bank_pending: PropTypes.bool,
	error: PropTypes.string,
	resetBankState: PropTypes.string,
	linkBank: PropTypes.string
}

const mapStateToProps = (state) => {
	return {
		user: state.userState.user,
		token: state.authState.accessToken,
		bank_pending: state.bankState.bank_pending,
		error: state.bankState.error
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		linkBank: (plaid_token, token, onSuccess, onErrorCallback) => dispatch(linkBank(plaid_token, token, onSuccess, onErrorCallback)),
		resetBankState: () => dispatch(resetBankState())
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
